<template>
  <div />
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    const tsignCode = this.$route.query.tsignCode || 1
    if (tsignCode !== 1) {
      this.$store.dispatch('user/setServiceSendStatus', true)
      this.$router.replace({ name: 'chatRoom', params: this.$route.params })
    }
  }
}
</script>
